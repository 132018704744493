import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "serviceProviderInput" ]
  updateServiceProviderOptionsUrl() {
    this.clearSupportableIdField()
    this.updateSupportableTypeField()
  }

  clearSupportableIdField(){
    let supportableIdInput = document.getElementById("support_ticket_supportable_id")
    supportableIdInput.innerHTML = ""
    supportableIdInput.disabled = true
    supportableIdInput.value = null
  }

  updateSupportableTypeField(){
    let supportableTypeInput = document.getElementById("support_ticket_supportable_type")
    var args = JSON.parse(supportableTypeInput.getAttribute("data-arguments"))[0]
    const url = new URL(args.url, window.location.origin);
    url.searchParams.set("service_provider_id", this.serviceProviderInputTarget.value);
    args.url = url.toString()
    var newArgs = JSON.stringify([args])
    supportableTypeInput.setAttribute("data-arguments", newArgs)
    supportableTypeInput.disabled = false
  }
}
