import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "idInput", "inputContainer" ]
  static values = {
    calcSettings: Array,
  }

  connect() {
    this.isProgrammaticChange = false;

    this.inputContainerTargets.forEach((container) => {
      container.querySelectorAll("input").forEach((input) => {
        input.addEventListener("input", () => {
          if (!this.isProgrammaticChange) {
            this.idInputTarget.value = null;
          }
        });
      });
    });
  }

  updateParameters(){
    const calcSettingValues = this.calcSettingsValue.find(setting => setting.id === this.idInputTarget.value);

    if (!calcSettingValues) return;

    this.isProgrammaticChange = true;

    Object.keys(calcSettingValues).forEach(key => {
      const inputElement = document.getElementById(`reclassified_scans_summary_${key}`);
      if (inputElement) {
        inputElement.value = calcSettingValues[key];
      }
    });

    this.isProgrammaticChange = false;
  }
}
