import Highcharts from "highcharts"
export const sharedChartOptions = {
  lang: {
    thousandsSep: ","
  },
  chart: {
    type: "bar",
  },
  tooltip: {
    enabled: false
  },
  plotOptions: {
    bar: {
      pointWidth: 50,
      dataLabels: {
        enabled: true,
        align: "left",
        inside: false,
        x: 5
      }
    },
    series: {
      enableMouseTracking: false,
      shadow: false,
      animation: false,
      pointPadding: 0.1,
      groupPadding: 0.05,
      dataLabels: {
        enabled: true,
        formatter: function() {
          return Highcharts.numberFormat(this.y, 0, '', ',');
        }
      }
    },
  },
  legend: false,
  xAxis: {
    labels: {
      style: {
        fontWeight: "bold",
        fontSize: "18px",
        fontFamily: "Inter"
      }
    },
    visible: true,
    gridLineWidth: 0
  },
  yAxis: {
    labels: {
      enabled: false
    },
    lineColor: 'black',
    lineWidth: 2,
    tickLength: 0,
    title: {
      text: 'p/m³',
      align: "high"
    },
    gridLineWidth: 0
  },
};

export function calculateDynamicHeight(categories, varsPerCategory = 1, baseHeightPerBar = 50, additionalMargin = 100) {
  return varsPerCategory * categories.size * baseHeightPerBar + additionalMargin;
}

export const extractCategoriesFromChartData = (chartData) => {
  const categories = new Set();
  chartData.forEach(series => {
    series.data.forEach(([area, _]) => {
      categories.add(area);
    });
  });
  return categories;
};

export const extractCategoriesFromGYRChartData = (chartData) => {
  return new Set(chartData[0].data.map(object => object.x));
};
