import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { allowDecimal: Boolean }

  connect() {
    this.element.addEventListener("keydown", this.preventInvalidInput.bind(this))
  }

  disconnect() {
    this.element.removeEventListener("keydown", this.preventInvalidInput.bind(this))
  }

  preventInvalidInput(event) {
    const key = event.key
    const input = event.target.value
    const isDecimalAllowed = this.allowDecimalValue

    if (key === "Backspace" || key === "Delete" || key === "ArrowLeft" || key === "ArrowRight" || key === "Tab") {
      return
    }

    if (/[0-9]/.test(key)) {
      return
    }

    if (isDecimalAllowed && key === "." && !input.includes(".")) {
      return
    }

    event.preventDefault()
  }
}
