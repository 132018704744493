import { Controller } from "@hotwired/stimulus"
import Highcharts from "highcharts"
import { calculateDynamicHeight, extractCategoriesFromChartData } from "./utils/chartSettings";

export default class extends Controller {
  connect() {
    const chartContainer = this.element;
    const chartData = JSON.parse(chartContainer.dataset.chartsData);
    const labelFormat = chartContainer.dataset.labelFormat;
    const chartTitle = chartContainer.dataset.title;
    const categories = extractCategoriesFromChartData(chartData);
    const chartHeight = calculateDynamicHeight(categories, 5, 25);

    Highcharts.setOptions({
      lang: {
        thousandsSep: ","
      }
    });
    Highcharts.chart(chartContainer, {
      chart: {
        type: "bar",
        height: chartHeight,
      },
      tooltip: {
        enabled: false
      },
      title: chartTitle ? {
        verticalAlign: "top",
        text: chartTitle,
        align: "left"
      } : { text: null },
      legend: {
        align: "left",
        verticalAlign: "top",
        layout: "horizontal",
        symbolHeight: 12,
        symbolWidth: 12,
        symbolRadius: 0,
        itemStyle: {
          fontWeight: "bold",
          fontSize: "13px"
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            align: "left",
            inside: false,
            x: 5
          }
        },
        series: {
          pointWidth: 20,
          pointPadding: 0.5,
          groupPadding: 0.1,
          dataLabels: {
            enabled: true,
            formatter: function() {
              // Format the number, then append " m³" to the label
              return Highcharts.numberFormat(this.y, 0, '', ',');
            }
          }
        }
      },
      xAxis: {
        categories: [...categories],
        labels: {
          style: {
            fontWeight: "bold",
            fontSize: "18px",
            fontFamily: "Inter"
          }
        },
        visible: true,
        gridLineWidth: 0
      },
      yAxis: {
        labels: {
          enabled: false
        },
        lineColor: 'black',
        lineWidth: 2,
        tickLength: 0,
        title: {
          text: 'p/m³',
          align: "high"
        },
        gridLineWidth: 0
      },
      series: chartData,
    })
  }
}
