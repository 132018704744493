import consumer from "./consumer"

consumer.subscriptions.create({ channel: "DataChannel", instrument_id: "123" }, {
  connected() {
    console.log("WebSocket connection opened");
  },

  disconnected() {
    console.log("WebSocket connection closed");
  },

  received(data) {
    console.log("Received data:", data);
    // Handle binary data here
  }
});
