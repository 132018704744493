import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  connect() {
    this.element.addEventListener("turbo:submit-end", (event) => {
      Turbo.clearCache()
      this.next(event, this.element.dataset.replace)
    })
  }

  next(event, replace) {
    if (event.detail.success) {
      let action = replace ? { action: "replace" } : { action: "advance" }
      Turbo.visit(event.detail.fetchResponse.response.url, action)
    }
  }
}
