import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "source" ]

  oneTimeCopy() {
    event.preventDefault()
    navigator.clipboard.writeText(event.target.dataset.content)
    event.target.style.cursor = "default"
    event.target.removeAttribute("data-action")
    event.target.classList.add("pointer-events-none")
      event.target.classList.remove("bg-primary-100", "hover:bg-primary-300")
    if (event.target.innerText.length > 0) {
      event.target.innerText = "Copied!"
      event.target.classList.add("bg-emerald-400")
    } else {
      event.target.innerHTML = "✔"
    }
  }
}
