import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "layer", "drawer", "background", "title", "subtitle", "body", "initialMessage", "form" ]

  connect() {
    this.drawerTarget.addEventListener('click', this.stopClickPropagation);

    this.bodyTarget.addEventListener('scroll', () => {
      const isAtBottom = this.bodyTarget.scrollTop + this.bodyTarget.clientHeight >= this.bodyTarget.scrollHeight;
      this.shouldAutoScroll = isAtBottom;
    });

    this.autoscrollForMessages();
    this.autofocusNewInput();
  }

  autoscrollForMessages() {
    const observer = new MutationObserver(mutations => {
      for (const mutation of mutations) {
        if (mutation.type === 'childList' && this.shouldAutoScroll) {
          this.smoothScrollTo(this.bodyTarget, this.bodyTarget.lastElementChild);
        }
      }
    });

    observer.observe(this.bodyTarget, { childList: true });
  }

  autofocusNewInput() {
    const formObserver = new MutationObserver(() => {
      const inputField = this.formTarget.querySelector('input[type="text"]');
      if (inputField) {
        inputField.focus();
      }
    });

    formObserver.observe(this.formTarget, { childList: true, subtree: true });
  }

  stopClickPropagation(event) {
    event.stopPropagation();
  }

  async setRemoteSource() {
    event.preventDefault()
    const url = event.currentTarget.dataset.url;
    const response = await fetch(url, {
      headers: { "Accept": "text/vnd.turbo-stream.html" }
    });

    const text = await response.text();
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, "text/html");
    const streams = doc.querySelectorAll("turbo-stream");
    const htmlContent = streams[0].querySelector("template").content;
    const initialMessage = htmlContent.getElementById("initial_message");
    const messageHistory = htmlContent.getElementById("message_history");
    const newMessageForm = htmlContent.getElementById("new_message_form");
    this.initialMessageTarget.innerHTML = initialMessage.innerHTML;
    this.bodyTarget.innerHTML = messageHistory.innerHTML;
    this.formTarget.innerHTML = newMessageForm.innerHTML;

    streams.forEach((element, index) => {
      if (index === 0) return;
      element.querySelector("template").content.childNodes.forEach((div) => {
        const id = div.getAttribute("id");
        document.getElementById(id).innerHTML = div.innerHTML
      })
    });
  }

  open() {
    this.layerTarget.classList.remove("hidden");
    const title = event.currentTarget.dataset.title;
    const subtitle = event.currentTarget.dataset.subtitle;
    this.titleTarget.innerText = title;
    this.subtitleTarget.innerText = subtitle || "";
    setTimeout(() => {
      this.drawerTarget.classList.remove("translate-x-full");
      this.drawerTarget.classList.add("translate-x-0");
    }, 10); // short delay
    setTimeout(() => {
      const lastMessage = this.bodyTarget.lastElementChild;
      if (lastMessage) {
        this.smoothScrollTo(this.bodyTarget, lastMessage);
      }
    }, 200); // short delay

  }

  close() {
    event.preventDefault();
    this.drawerTarget.classList.add("translate-x-full");
    this.drawerTarget.classList.remove("translate-x-0");
    setTimeout(() => {
      this.layerTarget.classList.add("hidden");
    }, 500);
    setTimeout(() => {
      this.titleTarget.innerText = "";
      this.subtitleTarget.innerText = "";
      this.bodyTarget.innerHTML = "";
    }, 500);
  }

  smoothScrollTo(container, target) {
    const startPosition = container.scrollTop;
    const endPosition = target.offsetTop;
    const distance = endPosition - startPosition;
    const duration = 750;
    let start = null;

    const step = (timestamp) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      const pace = this.easeInOutCubic(progress / duration);
      container.scrollTop = startPosition + distance * pace;

      if (progress < duration) {
        window.requestAnimationFrame(step);
      } else {
        container.scrollTop = startPosition + distance;
      }
    }

    window.requestAnimationFrame(step);
  }

  easeInOutCubic(t) {
    return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
  }
}

