import { Controller } from "@hotwired/stimulus"
import { createPopper } from "@popperjs/core"

export default class extends Controller {
  static targets = ["source", "content"]

  connect() {
    this.popper = createPopper(this.sourceTarget, this.contentTarget, {
      placement: 'top',
      modifiers: [
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['bottom'],
          },
        },
        {
          name: 'preventOverflow',
          options: {
            padding: 8,
          },
        },
        {
            name: 'offset',
            options: {
                offset: [0, 8],
            },
        },
      ],
    });

    this.contentTarget.classList.add('hidden');
  }

  show() {
    this.contentTarget.classList.remove('hidden');
    this.popper.update();
  }

  hide() {
    clearTimeout(this.timeout);
    this.contentTarget.classList.add('hidden');
  }
}
