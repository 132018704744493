import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage";

export default class extends Controller {
  static targets = ["input", "fileRowsContainer", "fileRow", "progressTemplate", "fileRowTemplate"]

  uploadFiles(event) {
    Array.from(this.inputTarget.files).forEach(file => {
      this.uploadFile(file)
    });
    this.inputTarget.value = ""
  }

  uploadFile(file) {
    const upload = new DirectUpload(file, "/rails/active_storage/direct_uploads", this)

    this.appendProgressBar
    upload.create((error, blob) => {
      if (error) {
        console.error("Upload error: ", error)
      } else {
        this.appendFileRow(blob, file.name)
      }
    });
  }

  appendProgressBar(){
    const uploadNode = this.progressTemplateTarget.content.cloneNode(true)
    const container = uploadNode.querySelector("[data-role='upload-container']")
    const progressBar = uploadNode.querySelector("[data-role='progress-bar']")
    const status = uploadNode.querySelector("[data-role='upload-status']")
    const filenameDisplay = uploadNode.querySelector("[data-role='filename-display']");
    filenameDisplay.textContent = file.name
    this.fileRowsContainerTarget.appendChild(container)
  }

  appendFileRow(blob, filename){
    const uploadNode = this.fileRowTemplateTarget.content.cloneNode(true)
    const hiddenInput = uploadNode.querySelector("input")
    hiddenInput.value = blob.signed_id;
    const filenameElem = uploadNode.querySelector("[data-role='filename']")
    const idElem = uploadNode.querySelector("[data-role='file-id']")
    idElem.setAttribute("data-id", blob.signed_id)
    filenameElem.textContent = filename
    this.fileRowsContainerTarget.appendChild(uploadNode)
  }

  removeUpload(){
    const signedId = event.target.dataset.id

    fetch(`/attachments/destroy_temporary`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").getAttribute('content')
      },
      body: JSON.stringify({ signed_id: signedId })
    })
    .then(response => {
      if (response.ok) {
        console.log('File deleted successfully');
      } else {
        console.error('Error deleting the file');
      }
    })

    this.fileRowTargets.filter((f) => f.querySelector("[data-role='file-id']").dataset.id == signedId )[0].remove()
  }
}
