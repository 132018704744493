import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="inputs--phone"
export default class extends Controller {
  static targets = ["phoneInput"]
  static values = { countryCode: String }

  connect() {
    this.format()
    this.setPlaceholder()
  }

  countryCodeValueChanged() {
    this.format()
    this.setPlaceholder()
  }

  format() {
    const phoneNumber = this.phoneInputTarget.value.replace(/\D/g, "")

    if (phoneNumber) {
      this.phoneInputTarget.value = this.formatPhoneNumber(phoneNumber)
    } else {
      this.phoneInputTarget.value = ""
    }
  }

  formatPhoneNumber(digits) {
    switch (this.countryCodeValue) {
      case "USA":
      case "CAN":
        return this.formatUSCANPhoneNumber(digits)
      case "GBR":
        return this.formatUKPhoneNumber(digits)
      default:
        return digits
    }
  }

  formatUSCANPhoneNumber(digits) {
    if (digits[0] === '1') {
      digits = digits.slice(1);
    }

    let formattedNumber = '';

    if (digits.length >= 3) {
      formattedNumber += `(${digits.slice(0, 3)})`;
    } else {
      formattedNumber += `(${digits}`;
    }

    if (digits.length > 3) {
      formattedNumber += ` ${digits.slice(3, 6)}`;
    } else if (digits.length > 3) {
      formattedNumber += digits.slice(3, 6);
    }

    if (digits.length > 6) {
      formattedNumber += `-${digits.slice(6, 10)}`;
    }

    return formattedNumber;
  }

  formatUKPhoneNumber(digits) {
    if (digits.startsWith("01") || digits.startsWith("02")) {
      // Landline
      if (digits.length >= 7) {
        return `${digits.slice(0, 3)} ${digits.slice(3, 7)} ${digits.slice(7, 11)}`
      } else if (digits >= 3) {
        return `${digits.slice(0, 3)} ${digits.slice(3, 7)}`
      } else {
        return digits
      }
    } else {
      // Mobile
      if (digits.length >= 7) {
        return `${digits.slice(0, 4)} ${digits.slice(4, 7)} ${digits.slice(7, 11)}`
      } else if (digits >= 4) {
        return `${digits.slice(0, 4)} ${digits.slice(4, 7)}`
      } else {
        return digits
      }
    }
  }

  setPlaceholder() {
    switch (this.countryCodeValue) {
      case "USA":
      case "CAN":
        this.phoneInputTarget.placeholder = "(234) 234-2323"
        this.phoneInputTarget.maxLength = 14
        break
      case "GBR":
        this.phoneInputTarget.placeholder = "020 1234 5678" // GBR landline
        this.phoneInputTarget.maxLength = 13
        break
      default:
        this.phoneInputTarget.placeholder = ""
        break
    }
  }
}
