import { Controller } from "@hotwired/stimulus"
import Highcharts from "highcharts"
import { sharedChartOptions, calculateDynamicHeight, extractCategoriesFromChartData } from "./utils/chartSettings";

export default class extends Controller {
  connect() {
    const chartContainer = this.element;
    const chartData = JSON.parse(chartContainer.dataset.chartsData);
    const labelFormat = chartContainer.dataset.labelFormat;
    const chartTitle = chartContainer.dataset.title;
    const categories = extractCategoriesFromChartData(chartData);
    const chartHeight = calculateDynamicHeight(categories);

    Highcharts.setOptions({
      lang: {
        thousandsSep: ","
      }
    });
    Highcharts.chart(chartContainer, {
      ...sharedChartOptions,
      chart: {
        ...sharedChartOptions.chart,
        height: chartHeight
      },
      title: chartTitle ? {
        verticalAlign: "top",
        text: chartTitle,
        align: "left"
      } : { text: null },
      xAxis: {
        ...sharedChartOptions.xAxis,
        categories: [...categories],
      },
      series: chartData,
    })
  }
}
