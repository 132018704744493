import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["shortcutable"];

  connect() {
    document.addEventListener("keydown", this.handleKeydown.bind(this));
  }

  disconnect() {
    document.removeEventListener("keydown", this.handleKeydown.bind(this));
  }

  handleKeydown(event) {
    if (!(event.metaKey || event.ctrlKey)) {
      return;
    }
    this.shortcutableTargets.forEach(target => {
      const shortcutKey = target.dataset.shortcutKey;
      if (event.key.toLowerCase() === shortcutKey.toLowerCase()) {
        event.preventDefault();

        if (target) {
          target.focus();
        }
      }
    });
  }
}
