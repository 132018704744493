import { Controller } from "@hotwired/stimulus"
import mermaid from "mermaid";

export default class extends Controller {
  static targets = [ "container" ]

  connect() {
    mermaid.initialize({
      startOnLoad: false, // manually started below
      theme: 'default'
    });

    mermaid.init(undefined, this.containerTarget);
  }
}
