import { Controller } from "@hotwired/stimulus"
import debounce from 'lodash.debounce'

export default class extends Controller {
  static targets = ['query', 'shrinkable', 'button', 'results']

  connect(){
    this.search = debounce(this.search, 750).bind(this)
  }

  search() {
    let url
    var baseUrl = this.data.get("url")
    if(baseUrl.includes("?")){
      baseUrl = baseUrl.replace(/page=\d+/,"")
      url = baseUrl + `&query=${this.queryTarget.value}`
    } else {
      url = baseUrl + `?query=${this.queryTarget.value}`
    }

    fetch(url, {
      headers:  {
        "Accept": "application/json"
      },
    }).then(response => response.json())
      .then((data) => {
        if (document.getElementById("results_count")) {
          document.getElementById("results_count").innerHTML = data.results_count;
        }
        document.getElementById("search_results").innerHTML = data.entries;

        if (document.getElementById("pagy_nav")) {
          document.getElementById("pagy_nav").innerHTML = data.pagy_nav;
        }
        if (document.getElementById("page_count")) {
          document.getElementById("page_count").innerHTML = data.page_count;
        }

        window.scrollTo(0, 0)
      })
  }
}
