import { Controller } from "@hotwired/stimulus"
import { toNormalised, fix } from "postcode"

export default class extends Controller {
  static targets = [ "input" ]
  static values = { countryCode: String }

  connect() {
    this.format()
    this.setPlaceholder()
  }

  countryCodeValueChanged() {
    this.format()
    this.setPlaceholder()
  }

  format() {
    const postalCode = this.inputTarget.value.replace(/\s+/g, "").toUpperCase()

    if (postalCode) {
      this.inputTarget.value = this.formatPostalCode(postalCode)
    } else {
      this.inputTarget.value = ""
    }
  }

  formatPostalCode(postalCode) {
    switch (this.countryCodeValue) {
      case "USA":
        return this.formatUSPostalCode(postalCode)
      case "CAN":
        return this.formatCanadianPostalCode(postalCode)
      case "GBR":
        return this.formatUKPostalCode(postalCode)
      default:
        return postalCode
    }
  }

  formatUSPostalCode(postalCode) {
    return postalCode.replace(/\D/g, "")
  }

  formatCanadianPostalCode(postalCode) {
    let formattedPostalCode = "";
    const cleanedPostalCode = postalCode.replace(/\s+/g, "").toUpperCase();

    cleanedPostalCode.split("").forEach((char, i) => {
      if (i % 2 === 0 && /[A-Z]/.test(char)) {
        formattedPostalCode += char;
      } else if (i % 2 === 1 && /[0-9]/.test(char)) {
        formattedPostalCode += char;
      }
    });
    if (formattedPostalCode.length > 3) {
      return `${formattedPostalCode.slice(0, 3)} ${formattedPostalCode.slice(3, 6)}`
    }
    else {
      return formattedPostalCode
    }
  }

  formatUKPostalCode(postalCode) {
    try {
      const fixedPostalCode = fix(postalCode);  // Correct common mistakes
      if (fixedPostalCode.length > 2) {
        const normalizedPostCode = toNormalised(fixedPostalCode);
        if (normalizedPostCode) {
          return normalizedPostCode;
        }
      }
      return fixedPostalCode;
    } catch (error) {
      console.error("Error formatting UK postal code:", error);
      return postalCode;
    }
  }

  setPlaceholder() {
    switch (this.countryCodeValue) {
      case "USA":
        this.inputTarget.placeholder = "12345"
        this.inputTarget.maxLength = 5
        break
      case "CAN":
        this.inputTarget.placeholder = "A1A 1A1"
        this.inputTarget.maxLength = 7
        break
      case "GBR":
        this.inputTarget.placeholder = "E8 4QJ"
        this.inputTarget.maxLength = 8
        break
      default:
        this.inputTarget.placeholder = ""
        break
    }
  }
}
