import { Controller } from "@hotwired/stimulus"
import Highcharts from "highcharts"
import { sharedChartOptions, calculateDynamicHeight, extractCategoriesFromGYRChartData } from "./utils/chartSettings";

export default class extends Controller {
  connect() {
    const chartContainer = this.element;
    const chartData = JSON.parse(chartContainer.dataset.chartsData);
    const labelFormat = chartContainer.dataset.labelFormat;
    const chartTitle = chartContainer.dataset.title;
    const categories = extractCategoriesFromGYRChartData(chartData);
    const chartHeight = calculateDynamicHeight(categories);
    const enhancedChartData = chartData.map(series => ({
      ...series,
      data: series.data.map(point => {
        const color = this.determineColor(point);
        return { y: point.y, color: color };
      })
    }));
    Highcharts.setOptions({
      lang: {
        thousandsSep: ","
      }
    });
    Highcharts.chart(chartContainer, {
      ...sharedChartOptions,
      chart: {
        ...sharedChartOptions.chart,
        height: chartHeight,
      },
      plotOptions: {
        ...sharedChartOptions.plotOptions,
        series: {
          dataLabels: {
            formatter: function() {
              return Highcharts.numberFormat(this.y, 0, '', ',') + ' p/m³';
            }
          }
        },
      },
      title: chartTitle ? {
        verticalAlign: "top",
        text: chartTitle,
        align: "left"
      } : { text: null },
      xAxis: {
        ...sharedChartOptions.xAxis,
        categories: [...categories],
      },
      series: enhancedChartData,
    })
  }

  determineColor(point) {
    if (point.x === "Baseline") {
      return '#3F7CAC'; // Default blue
    } else if (point.y > 12000) {
      return '#BE2A2A'; // red
    } else if (point.y > 8000) {
      return '#FFC750'; // yellow
    } else {
      return '#3F8E4F'; // green
    }
  }
}
