import { Controller } from "@hotwired/stimulus"
import { i18n } from "../../config/i18n"

export default class extends Controller {
  static targets = [ "input", "state" ]
  static values = {
    countryCode: { type: String, default: "USA" }
  }

  connect() {
    this.updateInputTargets()
  }

  updateCountryCode() {
    this.countryCodeValue = event.target.value
  }

  countryCodeValueChanged() {
    this.updateInputTargets()
  }

  updateInputTargets() {
    this.inputTargets.forEach((t) => {
      let controller = t.getAttribute("data-controller")
      t.setAttribute(`data-${controller}-country-code-value`, this.countryCodeValue)
    })
    this.updateStateSelect()
  }

  updateStateSelect() {
    if (this.hasStateTarget) {
      const countryCode = this.countryCodeValue
      this.stateTarget.innerHTML = ""

      switch (countryCode) {
        case "USA":
        case "CAN":
          const states = i18n.t(`states.${countryCode}`)
          const selectedState = this.stateTarget.dataset.initialValue
          this.stateTarget.disabled = false
          Object.entries(states).forEach(([value, label]) => {
            const option = document.createElement('option')
            option.value = value
            option.selected = value === selectedState
            option.textContent = label
            this.stateTarget.appendChild(option)
          })
          return
        case "GBR":
          this.stateTarget.disabled = true
          this.stateTarget.innerHTML = ""
          return
      }
    }
  }
}
