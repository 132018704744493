import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "button"]

  show(event) {
    const activeButton = event.target
    const activeDetailsSection = document.getElementById(`scan-details-${activeButton.dataset.targetId}`);
    this.buttonTargets.forEach((element) => {
      element.classList.add('--hoverable');
      element.classList.remove('--active');
    });

    activeButton.classList.remove('--hoverable')
    activeButton.classList.add('--active')

    this.contentTargets.forEach((element) => {
      element.classList.add('hidden');
      element.classList.remove('animate-fade-in', 'active');
    });

    activeDetailsSection.classList.remove('hidden');
    activeDetailsSection.classList.add('animate-fade-in', 'active');
  }
}
