import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "password", "passwordConfirmation", "errorContainer", "submitButton" ]
  static values = {
    minimumLength: Number
  }

  clearPasswordConfirmation() {
    this.passwordConfirmationTarget.value = ""
    this.submitButtonTarget.disabled = true
  }

  validateLength() {
    if(this.minimumLengthValue > this.passwordTarget.value.length) {
      let error = document.createElement('div');
      error.textContent = `Password must be at least ${this.minimumLengthValue} characters.`;
      error.classList.add("mt-3", "mb-3", "text-red-700", "ring-1", "ring-inset", "ring-red-800", "bg-red-200", "rounded-md", "p-2")
      this.errorContainerTarget.innerHTML = ""
      this.errorContainerTarget.appendChild(error);
      this.submitButtonTarget.disabled = true
    } else {
      this.errorContainerTarget.innerHTML = ""
    }
  }

  enableSubmitIfValid(){
    if(this.passwordTarget.value.length >= this.minimumLengthValue) {
      if(this.passwordTarget.value === this.passwordConfirmationTarget.value) {
        this.submitButtonTarget.disabled = false
      }
    } else {
      this.submitButtonTarget.disabled = true
    }
  }

  validateEquality() {
    if(this.passwordTarget.value !== this.passwordConfirmationTarget.value) {
      let error = document.createElement('div');
      error.classList.add("mt-3", "mb-3", "text-red-700", "ring-1", "ring-inset", "ring-red-800", "bg-red-200", "rounded-md", "p-2")
      error.textContent = "Passwords don't match.";
      this.errorContainerTarget.appendChild(error);
      this.submitButtonTarget.disabled = true
    } else {
      this.errorContainerTarget.innerHTML = ""
      this.validateLength()
      if(this.errorContainerTarget.childElementCount === 0){
        this.submitButtonTarget.disabled = false
      }
    }
  }
}
