import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "chevron" ]
  connect() {
    let id = this.element.dataset.id;
    let element = document.getElementById(id)
    let state = localStorage.getItem(id);
    if (state === 'open') {
      element.style.maxHeight = `${element.scrollHeight}px`;
      this.chevronTarget.style.transform = "rotate(90deg)";
    } else {
      element.classList.add("max-h-0");
    }
    this.chevronTarget.classList.add("transform", "transition-transform")
    element.classList.add("ease-in-out", "duration-300");
  }

  toggle() {
    let id = event.target.dataset.id
    let element = document.getElementById(id)
    if (element.classList.contains("max-h-0")) {
      element.style.maxHeight = `${element.scrollHeight}px`;
      localStorage.setItem(id, 'open');
      this.chevronTarget.style.transform = "rotate(90deg)";
    } else {
      element.style.maxHeight = 0;
      localStorage.removeItem(id);
      this.chevronTarget.style.transform = "rotate(0deg)";
    }
    element.classList.toggle("max-h-0");
  }
}
